.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}
/* .sidebar .sidebar-body .nav .nav-item :hover{
  background: rgb(215, 191, 137);
}
.sidebar .sidebar-body .nav .nav-item .active{
  background: rgb(215, 191, 137);;
} */
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}
.tabbed-screen-tabs {
  display: flex;
  justify-content: flex-start;
  gap: 10px; /* Adjust the gap between buttons */
}

.tabbed-screen-tabs button {
  background-color: #f0f0f0;
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.tabbed-screen-tabs button.active {
  background-color: #d7bf89;
  border: none;
  border-radius: 5px; 
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s;
  height: 100%;
}

.tabbed-screen-tabs button:hover {
  background-color: #ddd; /* Hover color for tab buttons */
}
.card-box {
  position: relative;
  color: #fff;
  /* padding: 20px 10px 40px; */
  margin: 20px 0px;
}
.App-link {
  color: #61dafb;
}
.filter{
width: 105%!important;
}
.form-label {
  display: flex!important;
}
/* BulkAddQuestionAudio.css */
.segmentHeading {
  background: #cfc0c0;
  /* border-radius: 100%; */
  padding-left: 12px;
  width: auto;
  /* height: 36px; */
  /* padding: 11px; */
  /* padding-top: 5px; */
}

.loader-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.8); /* Semi-transparent white background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
}
.form-switch .form-check-input{
  margin-left: 0px!important;
}
.loader {
  border: 8px solid #f3f3f3; /* Light grey */
  border-top: 8px solid #3498db; /* Blue */
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 1s linear infinite;
}
.active{
  background: rgb(215, 191, 137);
  color: white!important;
  /* padding: 10px */
  height: 0px;
}
.add-bulk{
  font-size: 16px!important;
}
.sidebar .sidebar-body .nav .nav-item .nav-link {
  padding: 10px!important;
  border-radius: 5px!important;
  margin-top: 8px!important;
}
/* ////////////////////////////////////// */



.switch {
 display: inline-block;
}
.switch input {
 display: none;
}
.switch small {
 display: inline-block;
 width: 43px;
 height: 18px;
 background: #455a64;
 border-radius: 30px;
 position: relative;
 cursor: pointer;
}
.switch small:after {
 content: "Free";
 position: absolute;
 color: #fff;
 font-size: 11px;
 font-weight: 600;
 width: 100%;
 left: 0px;
 text-align: right;
 padding: 0 4px;
 box-sizing: border-box;
 line-height: 18px;
}
.switch small:before {
 content: "";
 position: absolute;
 width: 12px;
 height: 12px;
 background: #fff;
 border-radius: 50%;
 top: 3px;
 left: 3px;
 transition: .3s;
 box-shadow: -3px 0 3px rgba(0,0,0,0.1);
}
.switch input:checked ~ small {
 background: #4fc5c5;
 transition: .3s;
}
.switch input:checked ~ small:before {
 transform: translate(25px, 0px);
 transition: .3s;
}
.switch input:checked ~ small:after {
 content: "Paid";
 text-align: left;
}
.switchSmall {
 display: inline-block;
}
.switchSmall input {
 display: none;
}

















/* ////////////////////////////// */
@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.blur {
  filter: blur(5px); /* Add blur effect to the background content */
}
/* .nav-link.newNav{
  color: white!important;
}
.nav{
  background: #000!important;
} */
/* .table > :not(caption) > * > * {
  padding-top: 10px!important;
  padding-bottom: 0px!important;
} */
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
